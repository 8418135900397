import { defineNuxtRouteMiddleware, navigateTo } from '#imports';

// Nuxt Middleware Handler
// eslint-disable-next-line consistent-return
export default defineNuxtRouteMiddleware(async (to, from) => {
  // "tablets-und-surfsticks" is now "tablets-und-zubehoer"
  if (to.fullPath && to.fullPath.startsWith('/tablets-und-surfsticks')) {
    return navigateTo(to.fullPath.replace(/^\/tablets-und-surfsticks/, '/tablets-und-zubehoer'), {
      redirectCode: 302,
    });
  }
});

// /tablets-und-surfsticks/tablet-zubehoer/_samsung_schwarz?minPrice=1
