import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2757265a = () => interopDefault(import('../pages/a-good-company.vue' /* webpackChunkName: "pages/a-good-company" */))
const _4e7ac21d = () => interopDefault(import('../pages/anker/index.vue' /* webpackChunkName: "pages/anker/index" */))
const _f7d3eb2e = () => interopDefault(import('../pages/bitte-kommen-sie-spaeter-wieder.vue' /* webpackChunkName: "pages/bitte-kommen-sie-spaeter-wieder" */))
const _4226e33d = () => interopDefault(import('../pages/google/index.vue' /* webpackChunkName: "pages/google/index" */))
const _0ab4f5ac = () => interopDefault(import('../pages/handyrechnung-aktion.vue' /* webpackChunkName: "pages/handyrechnung-aktion" */))
const _1ec7ff28 = () => interopDefault(import('../pages/handyvergleich.vue' /* webpackChunkName: "pages/handyvergleich" */))
const _70d9cd2d = () => interopDefault(import('../pages/infos-zum-online-kauf.vue' /* webpackChunkName: "pages/infos-zum-online-kauf" */))
const _5cc8faef = () => interopDefault(import('../pages/kontakt.vue' /* webpackChunkName: "pages/kontakt" */))
const _5d1f6c3c = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _6c354765 = () => interopDefault(import('../pages/merkliste.vue' /* webpackChunkName: "pages/merkliste" */))
const _3ff198da = () => interopDefault(import('../pages/oster-angebote.vue' /* webpackChunkName: "pages/oster-angebote" */))
const _67696421 = () => interopDefault(import('../pages/panzerglass.vue' /* webpackChunkName: "pages/panzerglass" */))
const _67c6516d = () => interopDefault(import('../pages/preisvorteile-sichern.vue' /* webpackChunkName: "pages/preisvorteile-sichern" */))
const _80307ed4 = () => interopDefault(import('../pages/ratenzahlung.vue' /* webpackChunkName: "pages/ratenzahlung" */))
const _faf0b056 = () => interopDefault(import('../pages/redirecting.vue' /* webpackChunkName: "pages/redirecting" */))
const _10b34a8c = () => interopDefault(import('../pages/samsung/index.vue' /* webpackChunkName: "pages/samsung/index" */))
const _1bc64fcd = () => interopDefault(import('../pages/samsung-cashback-aktion.vue' /* webpackChunkName: "pages/samsung-cashback-aktion" */))
const _1aa65bd8 = () => interopDefault(import('../pages/smart-home-entdecken.vue' /* webpackChunkName: "pages/smart-home-entdecken" */))
const _7b5179ec = () => interopDefault(import('../pages/stabiles-internet.vue' /* webpackChunkName: "pages/stabiles-internet" */))
const _245e6476 = () => interopDefault(import('../pages/themenwelten.vue' /* webpackChunkName: "pages/themenwelten" */))
const _408d1488 = () => interopDefault(import('../pages/xiaomi.vue' /* webpackChunkName: "pages/xiaomi" */))
const _4567d686 = () => interopDefault(import('../pages/xiaomi-smartphones.vue' /* webpackChunkName: "pages/xiaomi-smartphones" */))
const _5f74d153 = () => interopDefault(import('../pages/xiaomi-zubehoer.vue' /* webpackChunkName: "pages/xiaomi-zubehoer" */))
const _7152d237 = () => interopDefault(import('../pages/zahlung-per-handyrechnung.vue' /* webpackChunkName: "pages/zahlung-per-handyrechnung" */))
const _bb965c50 = () => interopDefault(import('../pages/anker/product-data.ts' /* webpackChunkName: "pages/anker/product-data" */))
const _6f4b99c2 = () => interopDefault(import('../pages/google/product-data.js' /* webpackChunkName: "pages/google/product-data" */))
const _30fdf3ae = () => interopDefault(import('../pages/hilfe/lieferstatus-und-retoure.vue' /* webpackChunkName: "pages/hilfe/lieferstatus-und-retoure" */))
const _cf13e068 = () => interopDefault(import('../pages/magenta-black-days/page-data.js' /* webpackChunkName: "pages/magenta-black-days/page-data" */))
const _19bb0b07 = () => interopDefault(import('../pages/samsung/product-data.ts' /* webpackChunkName: "pages/samsung/product-data" */))
const _501bada8 = () => interopDefault(import('../pages/shop/bestaetigung.vue' /* webpackChunkName: "pages/shop/bestaetigung" */))
const _215f391d = () => interopDefault(import('../pages/shop/brodos-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/brodos-zahlungsverifizierung" */))
const _64568210 = () => interopDefault(import('../pages/shop/fehler.vue' /* webpackChunkName: "pages/shop/fehler" */))
const _bd64f2c6 = () => interopDefault(import('../pages/shop/kundenformular.vue' /* webpackChunkName: "pages/shop/kundenformular" */))
const _88e03940 = () => interopDefault(import('../pages/shop/kundenformular/index.vue' /* webpackChunkName: "pages/shop/kundenformular/index" */))
const _039ef1b6 = () => interopDefault(import('../pages/shop/kundenformular/persoenliche-daten.vue' /* webpackChunkName: "pages/shop/kundenformular/persoenliche-daten" */))
const _6c8ef87e = () => interopDefault(import('../pages/shop/kundenformular/zahlungsart.vue' /* webpackChunkName: "pages/shop/kundenformular/zahlungsart" */))
const _e24e64cc = () => interopDefault(import('../pages/shop/telekom-bestaetigung.vue' /* webpackChunkName: "pages/shop/telekom-bestaetigung" */))
const _509961d9 = () => interopDefault(import('../pages/shop/telekom-bezahlung-information.vue' /* webpackChunkName: "pages/shop/telekom-bezahlung-information" */))
const _59f870da = () => interopDefault(import('../pages/shop/telekom-zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/telekom-zahlungsverifizierung" */))
const _69135b4d = () => interopDefault(import('../pages/shop/warenkorb.vue' /* webpackChunkName: "pages/shop/warenkorb" */))
const _23235041 = () => interopDefault(import('../pages/shop/zahlungsverifizierung.vue' /* webpackChunkName: "pages/shop/zahlungsverifizierung" */))
const _4d35d8ee = () => interopDefault(import('../pages/shop/zusammenfassung.vue' /* webpackChunkName: "pages/shop/zusammenfassung" */))
const _5b789843 = () => interopDefault(import('../pages/weitere-informationen/agb-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/agb-brodos" */))
const _0749d47c = () => interopDefault(import('../pages/weitere-informationen/sitemap.vue' /* webpackChunkName: "pages/weitere-informationen/sitemap" */))
const _4aaf75c0 = () => interopDefault(import('../pages/weitere-informationen/ueber-brodos.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-brodos" */))
const _343dc990 = () => interopDefault(import('../pages/weitere-informationen/ueber-qivicon.vue' /* webpackChunkName: "pages/weitere-informationen/ueber-qivicon" */))
const _aaa4453e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _31f1d47a = () => interopDefault(import('../pages/magenta-moments-2024/_slug.vue' /* webpackChunkName: "pages/magenta-moments-2024/_slug" */))
const _f4e4b032 = () => interopDefault(import('../special-pages/product-details.vue' /* webpackChunkName: "" */))
const _ffccdd50 = () => interopDefault(import('../special-pages/shop-list.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/a-good-company",
    component: _2757265a,
    name: "a-good-company"
  }, {
    path: "/anker",
    component: _4e7ac21d,
    name: "anker"
  }, {
    path: "/bitte-kommen-sie-spaeter-wieder",
    component: _f7d3eb2e,
    name: "bitte-kommen-sie-spaeter-wieder"
  }, {
    path: "/google",
    component: _4226e33d,
    name: "google"
  }, {
    path: "/handyrechnung-aktion",
    component: _0ab4f5ac,
    name: "handyrechnung-aktion"
  }, {
    path: "/handyvergleich",
    component: _1ec7ff28,
    name: "handyvergleich"
  }, {
    path: "/infos-zum-online-kauf",
    component: _70d9cd2d,
    name: "infos-zum-online-kauf"
  }, {
    path: "/kontakt",
    component: _5cc8faef,
    name: "kontakt"
  }, {
    path: "/maintenance",
    component: _5d1f6c3c,
    name: "maintenance"
  }, {
    path: "/merkliste",
    component: _6c354765,
    name: "merkliste"
  }, {
    path: "/oster-angebote",
    component: _3ff198da,
    name: "oster-angebote"
  }, {
    path: "/panzerglass",
    component: _67696421,
    name: "panzerglass"
  }, {
    path: "/preisvorteile-sichern",
    component: _67c6516d,
    name: "preisvorteile-sichern"
  }, {
    path: "/ratenzahlung",
    component: _80307ed4,
    name: "ratenzahlung"
  }, {
    path: "/redirecting",
    component: _faf0b056,
    name: "redirecting"
  }, {
    path: "/samsung",
    component: _10b34a8c,
    name: "samsung"
  }, {
    path: "/samsung-cashback-aktion",
    component: _1bc64fcd,
    name: "samsung-cashback-aktion"
  }, {
    path: "/smart-home-entdecken",
    component: _1aa65bd8,
    name: "smart-home-entdecken"
  }, {
    path: "/stabiles-internet",
    component: _7b5179ec,
    name: "stabiles-internet"
  }, {
    path: "/themenwelten",
    component: _245e6476,
    name: "themenwelten"
  }, {
    path: "/xiaomi",
    component: _408d1488,
    name: "xiaomi"
  }, {
    path: "/xiaomi-smartphones",
    component: _4567d686,
    name: "xiaomi-smartphones"
  }, {
    path: "/xiaomi-zubehoer",
    component: _5f74d153,
    name: "xiaomi-zubehoer"
  }, {
    path: "/zahlung-per-handyrechnung",
    component: _7152d237,
    name: "zahlung-per-handyrechnung"
  }, {
    path: "/anker/product-data",
    component: _bb965c50,
    name: "anker-product-data"
  }, {
    path: "/google/product-data",
    component: _6f4b99c2,
    name: "google-product-data"
  }, {
    path: "/hilfe/lieferstatus-und-retoure",
    component: _30fdf3ae,
    name: "hilfe-lieferstatus-und-retoure"
  }, {
    path: "/magenta-black-days/page-data",
    component: _cf13e068,
    name: "magenta-black-days-page-data"
  }, {
    path: "/samsung/product-data",
    component: _19bb0b07,
    name: "samsung-product-data"
  }, {
    path: "/shop/bestaetigung",
    component: _501bada8,
    name: "shop-bestaetigung"
  }, {
    path: "/shop/brodos-zahlungsverifizierung",
    component: _215f391d,
    name: "shop-brodos-zahlungsverifizierung"
  }, {
    path: "/shop/fehler",
    component: _64568210,
    name: "shop-fehler"
  }, {
    path: "/shop/kundenformular",
    component: _bd64f2c6,
    children: [{
      path: "",
      component: _88e03940,
      name: "shop-kundenformular"
    }, {
      path: "persoenliche-daten",
      component: _039ef1b6,
      name: "shop-kundenformular-persoenliche-daten"
    }, {
      path: "zahlungsart",
      component: _6c8ef87e,
      name: "shop-kundenformular-zahlungsart"
    }]
  }, {
    path: "/shop/telekom-bestaetigung",
    component: _e24e64cc,
    name: "shop-telekom-bestaetigung"
  }, {
    path: "/shop/telekom-bezahlung-information",
    component: _509961d9,
    name: "shop-telekom-bezahlung-information"
  }, {
    path: "/shop/telekom-zahlungsverifizierung",
    component: _59f870da,
    name: "shop-telekom-zahlungsverifizierung"
  }, {
    path: "/shop/warenkorb",
    component: _69135b4d,
    name: "shop-warenkorb"
  }, {
    path: "/shop/zahlungsverifizierung",
    component: _23235041,
    name: "shop-zahlungsverifizierung"
  }, {
    path: "/shop/zusammenfassung",
    component: _4d35d8ee,
    name: "shop-zusammenfassung"
  }, {
    path: "/weitere-informationen/agb-brodos",
    component: _5b789843,
    name: "weitere-informationen-agb-brodos"
  }, {
    path: "/weitere-informationen/sitemap",
    component: _0749d47c,
    name: "weitere-informationen-sitemap"
  }, {
    path: "/weitere-informationen/ueber-brodos",
    component: _4aaf75c0,
    name: "weitere-informationen-ueber-brodos"
  }, {
    path: "/weitere-informationen/ueber-qivicon",
    component: _343dc990,
    name: "weitere-informationen-ueber-qivicon"
  }, {
    path: "/",
    component: _aaa4453e,
    name: "index"
  }, {
    path: "/magenta-moments-2024/:slug?",
    component: _31f1d47a,
    name: "magenta-moments-2024-slug"
  }, {
    path: "/geraete/:slug?",
    component: _f4e4b032,
    name: "ProductDetails"
  }, {
    path: "/:config+",
    component: _ffccdd50,
    name: "ShopList"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
